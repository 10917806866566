import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { URL_DATE_FORMAT } from "../../shared/constants";

dayjs.extend(customParseFormat);

/**
 *
 * @param {string} activeDate: MM-DD-YYYY
 */
const getDaysOfWeek = (activeDate) => {
  const currentDayJsDate = dayjs(activeDate, URL_DATE_FORMAT);
  const startOfWeek = currentDayJsDate.startOf("week");

  return new Array(7)
    .fill(null)
    .map((_, idx) => dayjs(startOfWeek).add(idx, "day"))
    .map((dayJsDay) => dayjs(dayJsDay).format("YYYYMMDD"));
};

/**
 *
 * @param {string} activeDate: MM-DD-YYYY
 */
export const fetchDataForWeeks = (db) => async (userId, activeDate) => {
  const daysOfWeek = getDaysOfWeek(activeDate);

  const datesSnapshot = await db
    .collection("users")
    .doc(userId)
    .collection("dates")
    .where("id", "in", daysOfWeek)
    .get();

  const daysOfWeekData = datesSnapshot.docs.map((doc) => doc.data());

  return {
    daysOfWeek,
    daysOfWeekData,
  };
};
