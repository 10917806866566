import React, { createContext, useReducer, useContext } from "react";

export const FETCHING_DATA = "FETCHING_DATA";
export const FETCHED_DAILY_DATA_SUCCEED = "FETCHED_DAILY_DATA_SUCCEED";
export const FETCHED_DAILY_DATA_FAILED = "FETCHED_DAILY_DATA_FAILED";
export const ON_DRAG_END = "ON_DRAG_END";
export const ON_NEW_BULLET_SUBMIT = "ON_NEW_BULLET_SUBMIT";
export const ON_BULLET_DELETE = "ON_BULLET_DELETE";
export const PREPARE_BULLET_TO_UPDATE = "PREPARE_BULLET_TO_UPDATE";
export const CANCEL_BULLET_UPDATE = "CANCEL_BULLET_UPDATE";
export const ON_BULLET_UPDATE = "ON_BULLET_UPDATE";
export const CLEAR_BULLET_DATA = "CLEAR_BULLET_DATA";

export const initialState = {
  dailyJournalLoading: false,
  bulletToUpdate: null,
  bullets: {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case FETCHING_DATA:
      return {
        ...state,
        dailyJournalLoading: true,
      };
    case FETCHED_DAILY_DATA_FAILED:
      return {
        ...initialState,
        dailyJournalLoading: action.dailyJournalLoading,
      };
    case FETCHED_DAILY_DATA_SUCCEED:
      return {
        ...state,
        dailyJournalLoading: action.dailyJournalLoading,
        bullets: action.bullets,
      };
    // case ON_DRAG_END:
    //   return {
    //     ...state,
    //     columns: action.columns,
    //   };
    case ON_NEW_BULLET_SUBMIT:
      return {
        ...state,
        bullets: action.bullets,
      };
    case ON_BULLET_DELETE:
      return {
        ...state,
        bullets: action.bullets,
      };
    case PREPARE_BULLET_TO_UPDATE:
      return {
        ...state,
        bulletToUpdate: action.bulletToUpdate,
      };
    case CANCEL_BULLET_UPDATE:
      return {
        ...state,
        bulletToUpdate: null,
      };
    case ON_BULLET_UPDATE:
      return {
        ...state,
        bullets: action.bullets,
        bulletToUpdate: action.bulletToUpdate,
      };
    case CLEAR_BULLET_DATA:
      return {
        ...initialState,
      };
    default:
      throw new Error("unexpected action");
  }
};

export const BulletsContext = createContext(initialState);

export const BulletsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = React.useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state]);

  return (
    <BulletsContext.Provider value={value}>{children}</BulletsContext.Provider>
  );
};

export const useBullets = () => useContext(BulletsContext);
