import React from "react";
import styled from "styled-components/macro";
import { Form, Field, ErrorMessage } from "formik";
import { themeSpacing, themeColor } from "shared/theme";
import { Button, Input } from "antd";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  > button {
    margin-top: ${themeSpacing("baseAndHalf")};
  }
`;

const InputTitle = styled.span`
  display: block;
  margin-bottom: ${themeSpacing("half")};
`;

const LabelWithTopMargin = styled.label`
  margin-top: ${themeSpacing("baseAndHalf")};
`;

const StyledErrorMessage = styled.div`
  margin-top: ${themeSpacing("half")};
  color: ${themeColor("error")};
`;

const CustomInput = ({ field, form, type }) => (
  <Input
    {...field}
    type={type}
    onChange={form.handleChange}
    value={form.values[field.name]}
  />
);

const Presentation = (props) => (
  <StyledForm>
    <label htmlFor="Email">
      <InputTitle>Email</InputTitle>
      <Field
        type="email"
        name="email"
        placeholder="johnsnow@gmail.com"
        component={CustomInput}
      />
      <ErrorMessage name="email" component={StyledErrorMessage} />
    </label>

    <LabelWithTopMargin htmlFor="Password">
      <InputTitle>Password</InputTitle>
      <Field type="password" name="password" component={CustomInput} />
      <ErrorMessage name="password" component={StyledErrorMessage} />
    </LabelWithTopMargin>

    <Button type="primary" onClick={props.submitForm}>
      Log In
    </Button>
  </StyledForm>
);

export { Presentation };
