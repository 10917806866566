import React from "react";
import styled from "styled-components/macro";
import {
  themeFontSize,
  themeSpacing,
  themeBorderRadius,
  themeColor,
} from "../../shared/theme";

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${themeSpacing("eightTimes")};
  font-size: ${themeFontSize("xXLarge")};
  font-weight: bold;

  > span {
    display: inline-block;
    margin-right: ${themeSpacing("half")};
  }

  i {
    background-color: ${themeColor("grey", 800)};
  }
`;

const Spinner = styled.div`
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    border: 1px solid ${themeColor("grey", 400)};
    border-radius: ${themeBorderRadius("small")};
    margin-left: ${themeSpacing("quarter")};
    background-color: #333;

    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: rgb(235, 235, 235);
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: rgb(0, 211, 176);
  }

  .bounce3 {
    -webkit-animation-delay: -0.08s;
    animation-delay: -0.08s;
    background-color: rgb(0, 112, 255);
  }

  .bounce4 {
    background-color: rgb(241, 58, 68);
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Emoji = styled.span`
  color: ${themeColor("grey", 800)};
`;

type Props = {
  message?: string;
  onlyDots?: boolean;
};

export const ScreenLoader = (props: Props) => (
  <LoadingWrapper>
    {!props.onlyDots && (
      <Emoji role="img" aria-label="building-blocks-emojis-loader">
        {props.message || "👷‍♀️👷‍♂️ Building the blocks"}
      </Emoji>
    )}
    <Spinner>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
      <div className="bounce4"></div>
    </Spinner>
  </LoadingWrapper>
);
