import React from "react";
import { Formik } from "formik";
import ReactGA from "react-ga";
import { withFirebase } from "firebaseApp";
import { Presentation } from "./Presentation";

const LoginForm = withFirebase((props) => (
  <Formik
    initialValues={{ email: "", password: "" }}
    validate={(values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      return errors;
    }}
    onSubmit={async (values, formProps) => {
      ReactGA.event({
        category: "AuthButton",
        action: "Click",
        label: "LoginSubmit",
      });

      try {
        const response = await props.firebase.loginUserFromEmailAndPassword(
          values.email,
          values.password
        );
        if (response.error) {
          const { code, message } = response.error;

          if (code === "auth/user-not-found" || code === "auth/invalid-email") {
            formProps.setFieldError("email", message);
          } else if (
            code === "auth/wrong-password" ||
            code === "auth/invalid-password"
          ) {
            formProps.setFieldError("password", message);
          }
        } else {
          props.closeModal();
        }
      } catch (error) {
        console.error(error.message);
      }
    }}
  >
    {(formProps) => <Presentation {...formProps} />}
  </Formik>
));

export { LoginForm };
