import React from "react";
import styled from "styled-components/macro";

const Icon = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const svgString = `<svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch -->
	<title>Icon</title>
	<desc>Created with Sketch.</desc>
	<defs></defs>
	<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="Icon">
			<g id="Group">
				<rect id="Rectangle-3" fill="#F13A44" x="0" y="15" width="14" height="14"></rect>
				<rect id="Rectangle-3-Copy-2" fill="#EBEBEB" x="0" y="0" width="14" height="14"></rect>
				<rect id="Rectangle-3-Copy" fill="#0070FF" x="15" y="15" width="14" height="14"></rect>
				<rect id="Rectangle-3-Copy-3" fill="#00D3B0" x="15" y="0" width="14" height="14"></rect>
			</g>
		</g>
	</g>
</svg>`;

export const BlocksIcon = () => (
  <Icon dangerouslySetInnerHTML={{ __html: svgString }} />
);
