import {
  ParentOrder,
  Parents,
  Categories,
  CategoriesWithParentInfo,
} from "../types/categories";

export const parentOrder: ParentOrder = [
  "sleep",
  "work",
  "learn",
  "go",
  "food",
  "leisure",
  "exercise",
  "social",
  "misc",
];

export const parents: Parents = {
  sleep: {
    label: "Sleep",
    categories: ["sleep", "wakeUp", "bedtime"],
    color: "#220F85",
  },
  work: {
    label: "Work",
    categories: ["work", "afterWorkEvent", "meeting", "email"],
    color: "#3472F2",
  },
  learn: {
    label: "Learn",
    categories: ["school", "study"],
    color: "#57B4D2",
  },
  go: {
    label: "Go",
    categories: ["commute", "drive", "train", "subway", "travel"],
    color: "#64CFB2",
  },
  food: {
    label: "Food",
    categories: [
      "cook",
      "breakfast",
      "lunch",
      "dinner",
      "brunch",
      "dessert",
      "coffee",
    ],
    color: "#4A9B7A",
  },
  leisure: {
    label: "Leisure",
    categories: [
      "art",
      "browsePhone",
      "doNothing",
      "movie",
      "television",
      "music",
      "read",
      "sideProject",
      "surfInternet",
      "videoGames",
    ],
    color: "#f5b824",
  },
  exercise: {
    label: "Exercise",
    categories: [
      "run",
      "walk",
      "bike",
      "gym",
      "playSport",
      "swim",
      "meditate",
      "yoga",
    ],
    color: "#621749",
  },
  social: {
    label: "Social",
    categories: [
      "seeFamily",
      "seeFriends",
      "pet",
      "significantOther",
      "bar",
      "wine",
      "party",
      "concert",
      "sportEvent",
      "comedy",
    ],
    color: "#DC4C4F",
  },
  misc: {
    label: "Misc",
    categories: [
      "getReady",
      "shop",
      "doctor",
      "haircut",
      "houseWork",
      "clean",
      "laundry",
    ],
    color: "#EC794F",
  },
};

export const categories: Categories = {
  sleep: { emoji: "😴", label: "Sleep", value: "sleep", id: "sleep" },
  wakeUp: { emoji: "☀️", label: "Wake Up", value: "wakeUp", id: "wakeUp" },
  bedtime: {
    emoji: "🛌",
    label: "Bedtime",
    value: "bedtime",
    id: "bedtime",
  },
  work: { emoji: "📆", label: "Work", value: "work", id: "work" },
  afterWorkEvent: {
    emoji: "🗣",
    label: "After Work Event",
    value: "afterWorkEvent",
    id: "afterWorkEvent",
  },
  meeting: { emoji: "📝", label: "Meeting", value: "meeting", id: "meeting" },
  email: { emoji: "⌨️", label: "Email", value: "email", id: "email" },
  school: { emoji: "🎒", label: "School", value: "school", id: "school" },
  study: { emoji: "📚", label: "Study", value: "study", id: "study" },
  commute: {
    emoji: "🚂",
    label: "Commute",
    value: "commute",
    id: "commute",
  },
  drive: { emoji: "🚗", label: "Drive", value: "drive", id: "drive" },
  train: { emoji: "🚆", label: "Train", value: "train", id: "train" },
  subway: { emoji: "🚉", label: "Subway", value: "subway", id: "subway" },
  travel: { emoji: "✈", label: "Travel", value: "travel", id: "travel" },
  cook: { emoji: "👨‍🍳", label: "Cook", value: "cook", id: "cook" },
  breakfast: {
    emoji: "🍳",
    label: "Breakfast",
    value: "breakfast",
    id: "breakfast",
  },
  lunch: { emoji: "🍞", label: "Lunch", value: "lunch", id: "lunch" },
  dinner: { emoji: "🍝", label: "Dinner", value: "dinner", id: "dinner" },
  brunch: { emoji: "🍹", label: "Brunch", value: "brunch", id: "brunch" },
  dessert: {
    emoji: "🍪",
    label: "Dessert",
    value: "dessert",
    id: "dessert",
  },
  coffee: { emoji: "☕", label: "Coffee", value: "coffee", id: "coffee" },
  art: { emoji: "🎨", label: "Art", value: "art", id: "art" },
  browsePhone: {
    emoji: "📱",
    label: "Browse Phone",
    value: "browsePhone",
    id: "browsePhone",
  },
  doNothing: {
    emoji: "💭",
    label: "Do Nothing",
    value: "doNothing",
    id: "doNothing",
  },
  movie: { emoji: "🍿", label: "Movie", value: "movie", id: "movie" },
  television: {
    emoji: "📺",
    label: "Television",
    value: "television",
    id: "television",
  },
  music: { emoji: "🎸", label: "Music", value: "music", id: "music" },
  read: { emoji: "📚", label: "Read", value: "read", id: "read" },
  sideProject: {
    emoji: "✏️",
    label: "Side Project",
    value: "sideProject",
    id: "sideProject",
  },
  surfInternet: {
    emoji: "🌎",
    label: "Browse Web",
    value: "surfInternet",
    id: "surfInternet",
  },
  videoGames: {
    emoji: "🎮",
    label: "Video Games",
    value: "videoGames",
    id: "videoGames",
  },
  seeFamily: {
    emoji: "🏡",
    label: "See Family",
    value: "seeFamily",
    id: "seeFamily",
  },
  seeFriends: {
    emoji: "🗣",
    label: "See Friends",
    value: "seeFriends",
    id: "seeFriends",
  },
  pet: {
    emoji: "🐶",
    label: "Pet",
    value: "pet",
    id: "pet",
  },
  significantOther: {
    emoji: "💕",
    label: "Loved One",
    value: "significantOther",
    id: "significantOther",
  },
  bar: { emoji: "🍻", label: "Bar", value: "bar", id: "bar" },
  wine: { emoji: "🍷", label: "Wine", value: "wine", id: "wine" },
  party: { emoji: "🎉", label: "Party", value: "party", id: "party" },
  concert: {
    emoji: "🎶",
    label: "Concert",
    value: "concert",
    id: "concert",
  },
  sportEvent: {
    emoji: "🏟",
    label: "Sport Event",
    value: "sportEvent",
    id: "sportEvent",
  },
  comedy: { emoji: "🎭", label: "Comedy", value: "comedy", id: "comedy" },

  run: { emoji: "🏃", label: "Run", value: "run", id: "run" },
  walk: { emoji: "🚶", label: "Walk", value: "walk", id: "walk" },
  bike: { emoji: "🚴", label: "Bike", value: "bike", id: "bike" },
  gym: {
    emoji: "🏋",
    label: "Gym",
    value: "gym",
    id: "gym",
  },
  playSport: {
    emoji: "⚾",
    label: "Play Sport",
    value: "playSport",
    id: "playSport",
  },
  swim: { emoji: "🏊", label: "Swim", value: "swim", id: "swim" },
  meditate: {
    emoji: "🧘",
    label: "Meditate",
    value: "meditate",
    id: "meditate",
  },
  yoga: { emoji: "🤸", label: "Yoga", value: "yoga", id: "yoga" },
  getReady: {
    emoji: "🚿",
    label: "Get Ready",
    value: "getReady",
    id: "getReady",
  },
  shop: { emoji: "🛒", label: "Shop", value: "shop", id: "shop" },
  doctor: { emoji: "👩", label: "Doctor", value: "doctor", id: "doctor" },
  haircut: {
    emoji: "💈",
    label: "Haircut",
    value: "haircut",
    id: "haircut",
  },
  houseWork: {
    emoji: "🛠",
    label: "House Work",
    value: "houseWork",
    id: "houseWork",
  },
  clean: { emoji: "🧤", label: "Clean", value: "clean", id: "clean" },
  laundry: { emoji: "🧺", label: "Laundry", value: "laundry", id: "laundry" },
};

/**
  Returns the categories object but with the parent information added to each category
  first map returns
  returns [
    {label: "Sleep", value: "sleep", id: "sleep", parentLabel: "sleep", color: "#220F85"}
    {label: "Wake Up", value: "wakeUp", id: "wakeUp", parentLabel: "sleep", color: "#220F85"}
    {label: "Bedtime", value: "bedtime", id: "bedtime", parentLabel: "eep", color: "#220F85"},
    Array(2), Array(2), Array(5), Array(7), Array(10), Array(8), Array(8), Array(6)
  ]

  final reduce returns
  {
  sleep: {label: "Sleep", value: "sleep", id: "sleep", parentLabel: "Sleep", parentId: "sleep", color: "#220F85"} },
  wakeUp: {label: "Wake Up", value: "wakeUp", id: "wakeUp", parentLabel: "Sleep", parentId: "sleep", color: "#220F85"},
  ...etc
  }
*/
export const categoriesLookupTableWithParentInfo: CategoriesWithParentInfo = Object.keys(
  parents
)
  .map((key) => {
    const parent = parents[key];
    return parent.categories.map((category) => {
      return {
        ...categories[category],
        parentLabel: parent.label,
        parentId: key,
        color: parent.color,
      };
    });
  })
  .reduce((acc, curr) => [...acc, ...curr], [])
  .reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: curr,
    };
  }, {});
