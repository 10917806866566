import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Div100vh from "react-div-100vh";
import ReactGA from "react-ga";
import styled, { ThemeProvider } from "styled-components/macro";
import { theme } from "shared/theme";
import { URL_DATE_FORMAT, DATA_PAGE_LOADING_MESSAGE } from "shared/constants";
import { ScreenLoader } from "components/ScreenLoader";
import { PageLayout } from "components/PageLayout";
import dayjs from "dayjs";
import { withFirebase } from "firebaseApp";
import { PLAYGROUND_SECRET, METRICS_SECRET } from "shared/secrets";
// import { Elements, StripeProvider } from "react-stripe-elements";
import {
  FETCH_USER_SUCCESS,
  FETCH_USER_NO_USER,
  FETCH_USER_ERROR,
  UserProvider,
  useUserContext,
} from "./providers/useUserContext";
import { BulletsProvider } from "./providers/useBulletsContext";
import { whiteListedIds } from "./shared/betaAccess";

const Main = React.lazy(() => import("screens/Main"));
const Playground = React.lazy(() => import("screens/Playground"));
const DataPage = React.lazy(() => import("screens/Data"));
const Versions = React.lazy(() => import("screens/Versions"));
const Metrics = React.lazy(() => import("screens/Metrics"));
const Account = React.lazy(() => import("screens/Account"));
const Categories = React.lazy(() => import("screens/Categories"));

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  font-size: 1rem;
  font-weight: bold;
`;

const AppBaseWrapped = ({ firebase }) => {
  // const params = useParams();
  const userContext = useUserContext();

  /**
   * Test code to set secrets
   */
  // localStorage.setItem("PLAYGROUND_SECRET", PLAYGROUND_SECRET);
  // localStorage.setItem("METRICS_SECRET", METRICS_SECRET);

  const playgroundSecret = localStorage.getItem("PLAYGROUND_SECRET");
  const metricsSecret = localStorage.getItem("METRICS_SECRET");

  // Listen to the Firebase Auth state and set the local state.
  React.useEffect(() => {
    ReactGA.initialize("UA-49930663-3", {
      // debug: process.env.NODE_ENV === "development",
      titleCase: false,
      gaOptions: {
        userId: userContext.state.user && userContext.state.user.id,
      },
    });

    ReactGA.pageview(window.location.origin);

    firebase.auth.onAuthStateChanged(async (user) => {
      if (!user) {
        userContext.dispatch({
          type: FETCH_USER_NO_USER,
        });
      } else {
        const { fetchedUser, categories, error } = await firebase.fetchUser(
          user.uid
        );

        if (!fetchedUser || fetchedUser.error) {
          userContext.dispatch({
            type: FETCH_USER_ERROR,
          });
        } else {
          userContext.dispatch({
            type: FETCH_USER_SUCCESS,
            payload: {
              user: {
                emailVerified: user.emailVerified,
                displayName: fetchedUser.name,
                is12HourClock: fetchedUser.is12HourClock,
                id: fetchedUser.id,
                email: fetchedUser.email,
                priceTier: fetchedUser.priceTier,
                stripeId: fetchedUser.stripeId,
                subscriptionId: fetchedUser.subscriptionId,
              },
              categories,
            },
          });
        }
      }
    });
  }, []);

  return (
    <Router>
      <Div100vh>
        {userContext.state.isLoadingUser &&
          !userContext.state.errorFetchingUser && (
            <PageLayout>
              <ScreenLoader onlyDots />
            </PageLayout>
          )}

        {userContext.state.errorFetchingUser && (
          <PageLayout>
            <ErrorWrapper>
              Error fetching user{" "}
              <span role="img" aria-label="sad-face">
                😔
              </span>{" "}
              Please refresh page...
            </ErrorWrapper>
          </PageLayout>
        )}

        {!userContext.state.isLoadingUser &&
          !userContext.state.errorFetchingUser && (
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Redirect to={`/${dayjs().format(URL_DATE_FORMAT)}`} />
                )}
              />

              <Route
                exact
                path="/:date/playground"
                render={(props) =>
                  playgroundSecret === PLAYGROUND_SECRET ? (
                    <PageLayout>
                      <Suspense fallback={<ScreenLoader onlyDots />}>
                        <Playground {...props} />
                      </Suspense>
                    </PageLayout>
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                exact
                path="/account"
                render={() =>
                  !userContext.state.isLoadingUser && userContext.state.user ? (
                    <PageLayout>
                      <Suspense fallback={<ScreenLoader onlyDots />}>
                        <Account />
                      </Suspense>
                    </PageLayout>
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                exact
                path="/versions"
                render={() => (
                  <PageLayout>
                    <Suspense fallback={<ScreenLoader onlyDots />}>
                      <Versions />
                    </Suspense>
                  </PageLayout>
                )}
              />

              <Route
                exact
                path="/metrics"
                render={() =>
                  metricsSecret === METRICS_SECRET ? (
                    <PageLayout>
                      <Suspense fallback={<ScreenLoader onlyDots />}>
                        <Metrics />
                      </Suspense>
                    </PageLayout>
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                exact
                path="/:date/data"
                render={() => (
                  <PageLayout>
                    <Suspense
                      fallback={
                        <ScreenLoader message={DATA_PAGE_LOADING_MESSAGE} />
                      }
                    >
                      <DataPage />
                    </Suspense>
                  </PageLayout>
                )}
              />

              <Route
                exact
                path="/categories"
                render={() =>
                  userContext.state.user &&
                  whiteListedIds.includes(userContext.state.user.id) ? (
                    <PageLayout>
                      <Suspense
                        fallback={
                          <ScreenLoader message="Loading your categories" />
                        }
                      >
                        <Categories />
                      </Suspense>
                    </PageLayout>
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                exact
                path="/:date"
                render={(props) => (
                  <PageLayout>
                    <Suspense fallback={<ScreenLoader />}>
                      <Main {...props} />
                    </Suspense>
                  </PageLayout>
                )}
              />
            </Switch>
          )}
      </Div100vh>
    </Router>
  );
};

const AppBase = (props) => (
  <ThemeProvider theme={theme}>
    {/* <StripeProvider apiKey="pk_test_5CBjIDv7dwXIvo3Q4phIPzxx"> */}
    {/* <Elements> */}
    <UserProvider>
      <BulletsProvider>
        <AppBaseWrapped {...props} />
      </BulletsProvider>
    </UserProvider>
    {/* </Elements> */}
    {/* </StripeProvider> */}
  </ThemeProvider>
);

export const App = withFirebase(AppBase);
