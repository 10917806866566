import React from "react";
import { Formik } from "formik";
import ReactGA from "react-ga";
import { withFirebase } from "firebaseApp";
import { Presentation } from "./Presentation";
// import { useBullets } from "../../../providers/useBulletsContext";

const SignupForm = withFirebase((props) => {
  return (
    <Formik
      initialValues={{
        name: "",
        is12HourClock: true,
        email: "",
        password: "",
        passwordConfirmation: "",
      }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }

        if (values.password !== values.passwordConfirmation) {
          errors.passwordConfirmation = "Passwords must be the same";
        }
        return errors;
      }}
      onSubmit={async (values, formProps) => {
        ReactGA.event({
          category: "AuthButton",
          action: "Click",
          label: "SignUpSubmit",
        });

        try {
          const response = await props.firebase.signupUserFromEmailAndPassword(
            values.email,
            values.password,
            values.name,
            values.is12HourClock
          );
          if (response.error) {
            const { code, message } = response.error;

            if (
              code === "auth/email-already-in-use" ||
              code === "auth/invalid-email"
            ) {
              formProps.setFieldError("email", message);
            } else if (
              code === "auth/weak-password" ||
              code === "auth/invalid-password"
            ) {
              formProps.setFieldError("password", message);
            }
          } else {
            props.closeModal();
          }
        } catch (error) {
          console.error(error.message);
        }
      }}
    >
      {(formProps) => <Presentation {...formProps} />}
    </Formik>
  );
});

export { SignupForm };
