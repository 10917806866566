import React from "react";
import {
  parentOrder,
  parents,
  categories,
  categoriesLookupTableWithParentInfo,
} from "../../shared/categories";

export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const FETCH_USER_NO_USER = "FETCH_USER_NO_USER";
export const SIGN_USER_OUT = "SIGN_USER_OUT";

export const ADD_OR_REMOVE_CATEGORY = "ADD_OR_REMOVE_CATEGORY";
export const UPDATE_USER_TIME_PREFERENCE = "UPDATE_USER_TIME_PREFERENCE";

export const UPDATE_PARENT_COLOR = "UPDATE_PARENT_COLOR";

const combineParentsAndChildren = (categoryParents, children) =>
  Object.keys(categoryParents)
    .map((key) => {
      const parent = categoryParents[key];
      return parent.categories.map((category) => {
        return {
          ...children[category],
          parentLabel: parent.label,
          parentId: key,
          color: parent.color,
        };
      });
    })
    .reduce((acc, curr) => [...acc, ...curr], [])
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr,
      };
    }, {});

// displayName: "Test 2"
// email: "test2@gmail.com"
// emailVerified: false
// id: "8gDHRtJV7QMMQRzkDoYhKxsiHb52"
// is12HourClock: true
// priceTier: "FREE"
// stripeId: "cus_H7wGtewwhHc7GO"
// subscriptionId: undefined
const initialState = {
  user: null,
  categories: {
    parentOrder,
    parents,
    children: categories,
    categoriesLookupTableWithParentInfo,
  },
  isLoadingUser: true,
  errorFetchingUser: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        categories: {
          ...action.payload.categories,
          categoriesLookupTableWithParentInfo: combineParentsAndChildren(
            action.payload.categories.parents,
            action.payload.categories.children
          ),
        },
        isLoadingUser: false,
        errorFetchingUser: false,
      };
    case FETCH_USER_NO_USER:
      return {
        ...state,
        user: null,
        isLoadingUser: false,
        errorFetchingUser: false,
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        user: null,
        isLoadingUser: false,
        errorFetchingUser: true,
      };
    case SIGN_USER_OUT:
      return {
        ...state,
        user: null,
        errorFetchingUser: false,
      };
    case UPDATE_USER_TIME_PREFERENCE:
      return {
        ...state,
        user: {
          ...state.user,
          is12HourClock: !state.user.is12HourClock,
        },
      };
    case ADD_OR_REMOVE_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          parents: action.payload.newParents,
          children: action.payload.newChildren,
          categoriesLookupTableWithParentInfo: combineParentsAndChildren(
            action.payload.newParents,
            action.payload.newChildren
          ),
        },
      };
    case UPDATE_PARENT_COLOR:
      return {
        ...state,
        categories: {
          ...state.categories,
          parents: action.payload.newParents,
          categoriesLookupTableWithParentInfo: combineParentsAndChildren(
            action.payload.newParents,
            state.categories.children
          ),
        },
      };
    default:
      return state;
  }
};

export const UserContext = React.createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = React.useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => React.useContext(UserContext);
