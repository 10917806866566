import React from "react";
import { Link, useParams } from "react-router-dom";
import { Layout } from "antd";
import styled from "styled-components/macro";
import { themeColor, themeSpacing, screenSizes } from "../../shared/theme";
import { NavBar } from "../NavBar";
import { BlocksIcon } from "../BlocksIcon";
import { UrlParams } from "../../types/UrlParams";

const { Content, Sider } = Layout;

const TitleWrapperLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-top: 4px solid ${themeColor("primary")};
  align-items: center;
  padding: 1rem;
`;

const StyledTitle = styled.h1`
  font-family: "Nunito";
  margin: 0 0 0 ${themeSpacing("quarter")};
  font-weight: 900;
  font-size: 1.5rem;
  text-decoration: none;
  color: ${themeColor("grey", 900)};
`;

const StyledContent = styled(Content)`
  border-top: 4px solid ${themeColor("primary")};
`;

export const PageLayout = ({ children }) => {
  const params: UrlParams = useParams();
  const [screenSize, setScreenSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
  });

  return (
    <Layout
      style={{
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        style={{
          height: "100%",
          background: "white",
          position: screenSize <= screenSizes.tablet ? "absolute" : "relative",
          zIndex: 99,
        }}
        defaultCollapsed={screenSize <= screenSizes.tablet}
        zeroWidthTriggerStyle={{
          backgroundColor: "grey",
          top: "auto",
          bottom: "64px",
        }}
      >
        <div className="logo">
          <TitleWrapperLink to={`/${params.date || ""}`}>
            <BlocksIcon />
            <StyledTitle>144blocks</StyledTitle>
          </TitleWrapperLink>
        </div>
        <NavBar />
      </Sider>
      <StyledContent>{children}</StyledContent>
    </Layout>
  );
};
