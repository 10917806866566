export const prodConfig = {
  apiKey: 'AIzaSyC0aVRvS5DU1MeTilHjj3zA7pv3VveLAS0',
  authDomain: 'journee-bdcf1.firebaseapp.com',
  databaseURL: 'https://journee-bdcf1.firebaseio.com',
  projectId: 'journee-bdcf1',
  storageBucket: 'journee-bdcf1.appspot.com',
  messagingSenderId: '908648607089',
};

export const devConfig = {};
