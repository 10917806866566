import React from "react";
import { withFirebase } from "firebaseApp";
import ReactGA from "react-ga";
import { Button } from "antd";
import {
  SIGN_USER_OUT,
  useUserContext,
} from "../../../providers/useUserContext";
import { SUCCESS } from "../../../shared/constants";

const LogoutButtonUnWrapped = (props) => {
  const userContext = useUserContext();

  const onClick = async () => {
    ReactGA.event({
      category: "AuthButton",
      action: "Click",
      label: "Logout",
    });

    const response = await props.firebase.onLogoutClick();

    if (response === SUCCESS) {
      userContext.dispatch({
        type: SIGN_USER_OUT,
      });
    }
  };

  return (
    <Button type="secondary" onClick={onClick}>
      Log Out
    </Button>
  );
};

export const LogoutButton = withFirebase(LogoutButtonUnWrapped);
