import React, { useState } from "react";
import { LoginForm, SignupForm } from "../Auth";
import ReactGA from "react-ga";
import styled from "styled-components/macro";
import { theme, themeSpacing } from "../../shared/theme";
import { Button, Modal } from "antd";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleFormSection = styled.span`
  margin-top: ${themeSpacing("base")};

  > button {
    margin-top: ${themeSpacing("baseAndHalf")};
    margin-left: ${themeSpacing("half")};
  }
`;

export const AuthModal = ({ isModalOpen, closeModal }) => {
  ReactGA.modalview("authModal");

  const [isLoginActive, setIsLoginActive] = useState(true);
  const toggleIsLoginActive = () => {
    ReactGA.event({
      category: "AuthButton",
      action: "Click",
      label: isLoginActive ? "SwitchToSignUp" : "SwitchToLogin",
    });

    setIsLoginActive((prevState) => !prevState);
  };

  return (
    <Modal
      title={isLoginActive ? "👋 Welcome back! Log In" : "Sign Up"}
      visible={isModalOpen}
      onCancel={closeModal}
      footer={null}
    >
      <div>
        {isLoginActive ? (
          <Column>
            <LoginForm closeModal={closeModal} />

            <ToggleFormSection>
              Don't have an account?
              <Button onClick={toggleIsLoginActive}>Sign Up</Button>
            </ToggleFormSection>
          </Column>
        ) : (
          <Column>
            <SignupForm closeModal={closeModal} />

            <ToggleFormSection>
              Already have an account?
              <Button onClick={toggleIsLoginActive}>Log In</Button>
            </ToggleFormSection>
          </Column>
        )}
      </div>
    </Modal>
  );
};
