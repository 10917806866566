import { SUCCESS, ERROR } from "../../shared/constants";

export const addOrRemoveCategory = async (
  db,
  userId,
  newParents,
  newChildren
) => {
  const batch = db.batch();

  const parentsRef = db
    .collection("users")
    .doc(userId)
    .collection("categories")
    .doc("parents");
  batch.set(parentsRef, newParents);

  const childrenRef = db
    .collection("users")
    .doc(userId)
    .collection("categories")
    .doc("children");
  batch.set(childrenRef, newChildren);

  try {
    await batch.commit();

    return SUCCESS;
  } catch (err) {
    return ERROR;
  }
};

export const updateParentCategoryColor = async (db, userId, newParents) => {
  try {
    await db
      .collection("users")
      .doc(userId)
      .collection("categories")
      .doc("parents")
      .set(newParents);

    return SUCCESS;
  } catch (err) {
    return ERROR;
  }
};
