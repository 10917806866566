import React, { useState } from "react";
import { Modal, Input } from "antd";
import { useUserContext } from "../../providers/useUserContext";
import { withFirebase } from "../../firebaseApp";

const { TextArea } = Input;

export const FeedbackModal = withFirebase(
  ({ firebase, isModalOpen, closeModal }) => {
    const userContext = useUserContext();
    const [inputValue, setInputValue] = useState("");
    const [confirmLoading, setConfirmLoading] = useState(false);

    const onChange = (e) => setInputValue(e.target.value);

    const onSubmit = async () => {
      setConfirmLoading(true);

      await firebase.submitFeedback(inputValue, userContext.state.user);

      setConfirmLoading(false);
      closeModal();
    };

    return (
      <Modal
        title="We would 💖 to hear from you!"
        visible={isModalOpen}
        onOk={onSubmit}
        okText={confirmLoading ? "Sending" : "Submit"}
        okButtonProps={{
          disabled: inputValue === "",
        }}
        onCancel={closeModal}
        cancelText="Nevermind"
        confirmLoading={confirmLoading}
      >
        <TextArea
          rows={4}
          value={inputValue}
          onChange={onChange}
          placeholder="Found something you love or hate? Found a bug or want a new feature? Please let us know here..."
        />
      </Modal>
    );
  }
);
