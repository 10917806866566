import { css } from "styled-components/macro";

const SPACING_BASE = 1;

export const screenSizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
};

export const media: any = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args: any) => {
    const firstArg: any = args[0];

    return css`
      @media (min-width: ${screenSizes[label] / 16}em) {
        ${css(firstArg, ...args.slice(1))}
      }
    `;
  };

  return acc;
}, {});

export const themeSpacing = (size) => (props) => props.theme.spacing[size];

export const themeShadow = (size) => (props) => props.theme.shadows[size];

export const themeFontSize = (size) => (props) => props.theme.fontSize[size];

export const themeZIndex = (size) => (props) => props.theme.zIndex[size];

export const themeBorderRadius = (size) => (props) =>
  props.theme.borderRadius[size];

export const themeBoxShadow = (size) => (props) => props.theme.shadows[size];

export const themeColor = (color: string, shade?: number) => (props) => {
  return shade ? props.theme.colors[color][shade] : props.theme.colors[color];
};

export const theme = {
  widths: {
    maxPageWidth: "900px",
  },
  heights: {
    navBar: "60px",
    mobileButtonWrapper: "3rem",
  },
  shadows: {
    xSmall: "0 1px 3px hsla(0, 0%, 0%, .2)",
    small: "0 4px 6px hsla(0, 0%, 0%, .2)",
    medium: "0 5px 15px hsla(0, 0%, 0%, .2)",
    large: "0 10px 24px hsla(0, 0%, 0%, .2)",
    xLarge: "0 15px 35px hsla(0, 0%, 0%, .2)",
  },
  colors: {
    white: "white",
    green: "green",
    error: "#bb0000",
    primary: "#EF3D49",
    grey: {
      100: "hsl(210,17%,98%)",
      200: "hsl(207,22%,90%)",
      300: "hsl(210,24%,87%)",
      400: "hsl(208,17%,83%)",
      500: "hsl(210,23%,75%)",
      600: "hsl(215,17%,63%)",
      700: "hsl(214,11%,49%)",
      800: "hsl(215,17%,30%)",
      900: "hsl(215,22%,17%)",
    },
  },
  spacing: {
    quarter: `${SPACING_BASE * 0.25}rem`,
    half: `${SPACING_BASE * 0.5}rem`,
    threeQuarters: `${SPACING_BASE * 0.75}rem`,
    base: `${SPACING_BASE}rem`,
    baseAndHalf: `${SPACING_BASE * 1.5}rem`,
    twoTimes: `${SPACING_BASE * 2}rem`,
    threeTimes: `${SPACING_BASE * 3}rem`,
    fourTimes: `${SPACING_BASE * 4}rem`,
    sixTimes: `${SPACING_BASE * 6}rem`,
    eightTimes: `${SPACING_BASE * 8}rem`,
    twelveTimes: `${SPACING_BASE * 12}rem`,
    sixteenTimes: `${SPACING_BASE * 16}rem`,
    twentyFourTimes: `${SPACING_BASE * 24}rem`,
    thirtyTwoTimes: `${SPACING_BASE * 32}rem`,
    fortyTimes: `${SPACING_BASE * 40}rem`,
  },
  fontSize: {
    xSmall: ".75rem",
    small: ".875rem",
    base: "1rem",
    large: "1.125rem",
    xLarge: "1.25rem",
    xXLarge: "1.5rem",
    double: "2rem",
    triple: "3rem",
  },
  fonts: {
    primary: "Lato",
    title: "Cookie",
  },
  borderRadius: {
    small: "4px",
    large: "10px",
  },
  zIndex: {
    navBar: 999,
    mobileToggleButton: 10,
  },
};
