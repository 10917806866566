import firebase from "firebase/app";
import { SUCCESS } from "../shared/constants";
import { FREE, SUBSCRIPTION, CANCELLED } from "../shared/priceTiers";
import { FirebaseContext, withFirebase } from "./context";
import { prodConfig } from "./config";
import { Bullets } from "../types/Bullet";
import { User } from "../types/User";
import { fetchDataForWeeks } from "./utils/fetchDataForWeeks";
import {
  updateParentCategoryColor,
  addOrRemoveCategory,
} from "./utils/categories";
import {
  categories as children,
  parents,
  parentOrder,
} from "../shared/categories";

require("firebase/auth");
require("firebase/firestore");

// // ⚠️ TODO: Implement 2 different configs
// const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
const config = prodConfig;

class Firebase {
  firebase: any;
  auth: any;
  db: any;

  constructor() {
    firebase.initializeApp(config);

    this.firebase = firebase;
    this.auth = firebase.auth();
    this.db = firebase.firestore();
  }

  /*
   *
   *
   ** Auth API ***
   *
   *
   */

  loginUserFromEmailAndPassword = async (email, password) => {
    try {
      await this.auth.signInWithEmailAndPassword(email, password);
      return SUCCESS;
      // error is object with code: string and message: string
    } catch (error) {
      console.error("error in loginUserFromEmailAndPassword", error);
      return { error };
    }
  };

  /**
   * This function will trigger the cloud function:
   * createStripeCustomerWhenFirestoreUserCreated
   * which will create a new Stripe customer and save the
   * stripeId to the user in firebase
   */
  signupUserFromEmailAndPassword = async (
    email: string,
    password: string,
    name: string,
    is12HourClock: boolean
  ) => {
    try {
      const response = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await this.db
        .collection("users")
        .doc(response.user.uid)
        .set({
          id: response.user.uid,
          email,
          name,
          is12HourClock,
          priceTier: FREE,
          // error handling - don't authenticate when user isn't created...
          created_at: this.firebase.firestore.Timestamp.fromDate(new Date()),
        });
      return SUCCESS;
      // error is object with code: string and message: string
    } catch (error) {
      console.error("error in signupUserFromEmailAndPassword", error);
      return { error };
    }
  };

  onLogoutClick = async () => {
    try {
      await this.auth.signOut();
      return SUCCESS;
    } catch (error) {
      return error.message;
    }
  };

  /**
   * Delete Account
   */
  deleteAccount = async (userId) => {
    await this.auth.currentUser
      .delete()
      .then(async () => {
        await this.db
          .collection("users")
          .doc(userId)
          .delete()
          .then(() => "Delete success")
          .catch(() => "Delete error");
      })
      .catch(
        () => "There was an issue deleting your account, please try again."
      );
  };

  // *** User API ***

  fetchUser = async (userId) => {
    try {
      const doc = await this.db
        .collection("users")
        .doc(userId)
        .get();

      if (doc.exists) {
        const user = doc.data();

        if (user.hasCustomCategories) {
          try {
            const categoryDoc = await this.db
              .collection("users")
              .doc(userId)
              .collection("categories")
              .get();

            if (!categoryDoc.docs || categoryDoc.docs.length === 0) {
              throw new Error("Couldn't fetch custom categories...");
            }

            const categories = categoryDoc.docs.reduce(
              (acc, doc) => ({
                ...acc,
                [doc.id]: doc.data(),
              }),
              {}
            );

            return {
              fetchedUser: user,
              categories: {
                children: categories.children,
                parents: categories.parents,
                parentOrder: categories.parentOrder.order,
              },
            };
          } catch (error) {
            return { error };
          }
        } else {
          return {
            fetchedUser: doc.data(),
            categories: {
              parents,
              children,
              parentOrder,
            },
          };
        }
      }
    } catch (error) {
      console.error("error in fetchUser", error);
      return { error };
    }
  };

  changeUserHourPreference = async (
    userId: string,
    new12HourClockPreference: boolean
  ) => {
    try {
      await this.db
        .collection("users")
        .doc(userId)
        .update({
          is12HourClock: new12HourClockPreference,
        });

      return SUCCESS;
    } catch (error) {
      console.error("error in changeUserHourPreference", error);
      return { error };
    }
  };

  /**
   *
   * @param {string} userId
   * @param {string} date: format -> MM-DD-YYYY (ie: 01-27-2019)
   */
  fetchDailyJournal = async (userId, date) => {
    const [month, day, year] = date.split("-");

    try {
      const doc = await this.db
        .collection("users")
        .doc(userId)
        .collection("dates")
        .doc(`${year}${month}${day}`)
        .get();

      if (doc.exists) {
        return doc.data();
      }
    } catch (error) {
      console.error("error in fetchDailyJournal", error);
      return { error };
    }
  };

  /**
   * Fetch data for each day of week
   */
  fetchDataForWeeks = () => fetchDataForWeeks(this.db);

  /**
   *
   * ======
   * Categories
   * ======
   *
   */

  addOrRemoveCategory = (userId, newParents, newChildren) =>
    addOrRemoveCategory(this.db, userId, newParents, newChildren);

  updateParentCategoryColor = (userId, newParents) =>
    updateParentCategoryColor(this.db, userId, newParents);

  /**
   *
   * @param {string} userId
   * @param {string} date: format -> MM-DD-YYYY (ie: 01-27-2019)
   * @param {string[]} bulletOrderByIds: the order the bullets are in
   */
  updateDailyJournalBulletOrder = async (userId, date, bulletOrderByIds) => {
    const [month, day, year] = date.split("-");

    try {
      await this.db
        .collection("users")
        .doc(userId)
        .collection("dates")
        .doc(`${year}${month}${day}`)
        .update({ bulletOrderByIds });

      return SUCCESS;
    } catch (error) {
      console.error("error in updateDailyJournalBulletOrder", error);
      return { error };
    }
  };

  deleteBullet = async (userId, date, bullets: Bullets) => {
    const newBulletsContent = Object.keys(bullets).reduce(
      (acc: any, bulletId: any) => {
        const bullet = bullets[bulletId];
        return [...acc, ...bullet.content];
      },
      []
    );

    const [month, day, year] = date.split("-");
    const dateRef = this.db
      .collection("users")
      .doc(userId)
      .collection("dates")
      .doc(`${year}${month}${day}`);

    try {
      await dateRef.update({
        bullets,
        queryBullets: newBulletsContent,
      });

      return SUCCESS;
    } catch (error) {
      console.error("error in deleteBullet", error);
      return { error };
    }
  };

  addBulletToDailyJournal = async (
    userId: string,
    date: string,
    newBulletObj: Bullets
  ) => {
    const batch = this.db.batch();

    const [month, day, year] = date.split("-");

    const queryBullets = Object.keys(newBulletObj)
      .map((bulletId) => {
        const bullet = newBulletObj[bulletId];
        return bullet.content;
      })
      .reduce((acc, curr) => [...acc, ...curr], []);

    const userRef = this.db.collection("users").doc(userId);
    const dateRef = userRef.collection("dates").doc(`${year}${month}${day}`);

    dateRef.get().then(async (docSnapshot) => {
      if (!docSnapshot.exists) {
        batch.update(userRef, {
          uniqueDaysAddingBullets: this.firebase.firestore.FieldValue.increment(
            1
          ),
        });

        try {
          batch.set(dateRef, {
            id: `${year}${month}${day}`,
            year,
            month,
            day,
            bullets: newBulletObj,
            queryBullets,
          });
          await batch.commit();
          return SUCCESS;
        } catch (error) {
          console.error("err updateDailyJournalBulletOrder", error);
          return { error };
        }
      } else {
        try {
          batch.update(dateRef, {
            bullets: newBulletObj,
            queryBullets,
          });

          await batch.commit();
          return SUCCESS;
        } catch (error) {
          console.error("error in updateDailyJournalBulletOrder", error);
          return { error };
        }
      }
    });
  };

  /**
   *
   * @param {string} userId
   * @param {string} date: format -> MM-DD-YYYY (ie: 01-27-2019)
   * @param {{
   *   id: string
   *    content: string[]
   *    start?: number
   *    end?: number
   *    notes?: string
   * }} newBullet
   */
  updateIndividualBullet = async (userId, date, updatedBullet) => {
    const [month, day, year] = date.split("-");

    const userRef = this.db.collection("users").doc(userId);
    const dateRef = userRef.collection("dates").doc(`${year}${month}${day}`);

    dateRef.get().then(async (docSnapshot) => {
      const newBullets = docSnapshot.data().bullets.map((bullet) => {
        return bullet.id === updatedBullet.id ? updatedBullet : bullet;
      });

      const newQueryBullets = newBullets.reduce(
        (acc, curr) => [...acc, ...curr.content],
        []
      );

      try {
        await dateRef.update({
          bullets: newBullets,
          queryBullets: newQueryBullets,
        });
        return SUCCESS;
      } catch (error) {
        console.error("error in updateIndividualBullet", error);
        return { error };
      }
    });
  };

  getDataByCategory = async (userId, categoryId) => {
    const userRef = this.db.collection("users").doc(userId);
    const dateRef = userRef.collection("dates");

    const response = await dateRef
      .where("queryBullets", "array-contains", categoryId)
      .get();

    // TODO: Move logic in screens/data.js for filtering through results here
    // so front end gets exactly what is needed
    if (response.docs.length > 0) {
      return response.docs.map((doc) => doc.data());
    }
  };

  // *** Feedback form ***

  submitFeedback = async (input: string, user?: User) => {
    const feedbackRef = this.db.collection("feedback");

    try {
      const response = await feedbackRef.add({
        input,
        userId: user ? user.id : null,
        email: user ? user.email : null,
        displayName: user ? user.displayName : null,
        created_at: this.firebase.firestore.Timestamp.fromDate(new Date()),
      });

      return SUCCESS;
    } catch (err) {
      throw new Error(err);
    }
  };

  // *** Payments API ***

  /**
   *
   * @param {string} userId
   * @param {string} priceTier: SUBSCRIPTION
   * @param {string} subscriptionId
   */
  updateUserToPaidSubscription = async (userId, subscriptionId) => {
    try {
      await this.db
        .collection("users")
        .doc(userId)
        .update({
          priceTier: SUBSCRIPTION,
          subscriptionId,
        });

      return SUCCESS;
    } catch (error) {
      console.error("error in updateUserToPaidSubscription", error);
      return { error };
    }
  };

  cancelSubscription = async (userId) => {
    try {
      await this.db
        .collection("users")
        .doc(userId)
        .update({
          priceTier: CANCELLED,
        });

      return SUCCESS;
    } catch (error) {
      console.error("error in cancelSubscription", error);
      return { error };
    }
  };

  /**
   *
   * Metrics
   *
   */
  getTopUsers = async () => {
    return await this.db
      .collection("users")
      .where("uniqueDaysAddingBullets", ">=", 1)
      .get()
      .then((querySnapshot) => {
        const items: any[] = [];

        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          items.push(doc.data());
        });

        return items;
      });
  };

  getFeedback = async () => {
    return await this.db
      .collection("feedback")
      .get()
      .then((querySnapshot) => {
        const items: any[] = [];

        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          items.push(doc.data());
        });

        return items;
      });
  };
}

export { Firebase, FirebaseContext, withFirebase };
