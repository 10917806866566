import React, { useState } from "react";
import styled from "styled-components/macro";
import { FaInstagram, FaExternalLinkAlt } from "react-icons/fa";
import ReactGA from "react-ga";
import { Button, Menu } from "antd";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { themeColor, themeSpacing } from "../../shared/theme";
import { AuthModal } from "../AuthModal";
import { FeedbackModal } from "./FeedbackModal";
import { useUserContext } from "../../providers/useUserContext";
import { withFirebase } from "../../firebaseApp";
import { URL_DATE_FORMAT } from "../../shared/constants";
import { LogoutButton } from "../Auth";
import { whiteListedIds } from "../../shared/betaAccess";

const { SubMenu } = Menu;

const InverseButton = styled(Button)`
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;

  &:disabled,
  &:hover {
    background-color: transparent;
  }
`;

const IconLink = styled.a`
  display: flex !important;
  align-items: center;

  svg {
    margin-right: ${themeSpacing("quarter")};
  }

  &:hover {
    color: ${themeColor("primary")};
  }
`;

export const NavBar = withFirebase(({ firebase }) => {
  const params = useParams();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const { state } = useUserContext();

  const openAuthModal = () => {
    ReactGA.event({
      category: "AuthButton",
      action: "Click",
      label: "OpenAuthModal",
    });

    setIsAuthModalOpen(true);
  };

  const closeAuthModal = () => {
    ReactGA.event({
      category: "AuthButton",
      action: "Click",
      label: "CloseAuthModal",
    });
    setIsAuthModalOpen(false);
  };

  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  const MenuItemClick = (label) =>
    ReactGA.event({
      category: "NavBar",
      action: "Click",
      label,
    });

  const clickInstagram = () => MenuItemClick("Instagram");
  const clickFeedback = () => MenuItemClick("Feedback");
  const clickCharts = () => MenuItemClick("Charts");
  const clickCategories = () => MenuItemClick("Categories");
  const clickAccount = () => MenuItemClick("Account");
  const clickWaitButWhy = () => MenuItemClick("WaitButWhy");
  const clickOldVersion = () => MenuItemClick("OldVersion");

  return (
    <>
      <Menu mode="inline">
        <Menu.Item key="1" icon={<FaInstagram />} onClick={clickInstagram}>
          <IconLink
            href="https://www.instagram.com/144.blocks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram width="40" height="40" />
            <span>Instagram</span>
          </IconLink>
        </Menu.Item>

        <Menu.Item key="2" onClick={clickFeedback}>
          <InverseButton
            type="default"
            onClick={openFeedbackModal}
            disabled={state.isLoadingUser}
          >
            Feedback
          </InverseButton>
        </Menu.Item>

        <Menu.Divider />

        {state.user && (
          <Menu.Item key="3" onClick={clickAccount}>
            <Link to="/account">{state.user.displayName}</Link>
          </Menu.Item>
        )}

        <Menu.Item key="4" onClick={clickCharts}>
          <Link to={`/${params.date || dayjs().format(URL_DATE_FORMAT)}/data`}>
            Your Data
          </Link>
        </Menu.Item>

        {state.user && whiteListedIds.includes(state.user.id) && (
          <Menu.Item key="5" onClick={clickCategories}>
            <Link to="/categories">Your Categories</Link>
          </Menu.Item>
        )}

        <Menu.Divider />

        <SubMenu key="sub2" title="Extras">
          <Menu.Item key="6">
            <Link to="/versions">Version 2.4.1</Link>
          </Menu.Item>

          <Menu.Item key="7">
            <IconLink
              href="https://waitbutwhy.com/2016/10/100-blocks-day.html"
              target="_blank"
              rel="noopener noreferrer"
              onClick={clickWaitButWhy}
            >
              <FaExternalLinkAlt width="40" height="40" />
              <span>Inspired by: WBW</span>
            </IconLink>
          </Menu.Item>

          <Menu.Item key="8">
            <IconLink
              href="https://blocks-144.web.app/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={clickOldVersion}
            >
              <FaExternalLinkAlt width="40" height="40" />
              <span>Old 144blocks</span>
            </IconLink>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="9">
          {state.user ? (
            <LogoutButton />
          ) : (
            <Button
              type="primary"
              onClick={openAuthModal}
              disabled={state.isLoadingUser}
            >
              Sign Up
            </Button>
          )}
        </Menu.Item>
      </Menu>

      <AuthModal isModalOpen={isAuthModalOpen} closeModal={closeAuthModal} />
      <FeedbackModal
        isModalOpen={isFeedbackModalOpen}
        closeModal={closeFeedbackModal}
      />
    </>
  );
});
