import React from "react";
import styled from "styled-components/macro";
import { Form, Field, ErrorMessage } from "formik";
import { themeSpacing, themeColor } from "shared/theme";
import { Button, Input, Radio } from "antd";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  > button {
    margin-top: ${themeSpacing("baseAndHalf")};
  }
`;

const InputTitle = styled.span`
  display: block;
  margin-bottom: ${themeSpacing("half")};
`;

const LabelWithTopMargin = styled.label`
  margin-top: ${themeSpacing("baseAndHalf")};
`;

const StyledErrorMessage = styled.div`
  margin-top: ${themeSpacing("half")};
  color: ${themeColor("error")};
`;

const CustomInput = ({ field, form, type }) => (
  <Input
    {...field}
    type={type}
    onChange={form.handleChange}
    value={form.values[field.name]}
  />
);

const ClockTypeRadioButtons = ({ field }) => (
  <Radio.Group {...field}>
    <Radio value={true}>12 Hour</Radio>
    <Radio value={false}>24 Hour</Radio>
  </Radio.Group>
);

const Presentation = (props) => (
  <StyledForm onSubmit>
    <label htmlFor="Name">
      <InputTitle>Name</InputTitle>
      <Field type="text" name="name" component={CustomInput} />
      <ErrorMessage name="name" component={StyledErrorMessage} />
    </label>

    <LabelWithTopMargin htmlFor="is12HourClock">
      <InputTitle>Clock Type</InputTitle>
      <Field name="is12HourClock" component={ClockTypeRadioButtons} />
    </LabelWithTopMargin>

    <LabelWithTopMargin htmlFor="Email">
      <InputTitle>Email</InputTitle>
      <Field type="email" name="email" component={CustomInput} />
      <ErrorMessage name="email" component={StyledErrorMessage} />
    </LabelWithTopMargin>

    <LabelWithTopMargin htmlFor="Password">
      <InputTitle>Password</InputTitle>
      <Field type="password" name="password" component={CustomInput} />
      <ErrorMessage name="password" component={StyledErrorMessage} />
    </LabelWithTopMargin>

    <LabelWithTopMargin htmlFor="passwordConfirmation">
      <InputTitle>Password Confirmation</InputTitle>
      <Field
        type="password"
        name="passwordConfirmation"
        component={CustomInput}
      />
      <ErrorMessage
        name="passwordConfirmation"
        component={StyledErrorMessage}
      />
    </LabelWithTopMargin>

    <Button type="primary" onClick={props.submitForm}>
      Sign Up
    </Button>
  </StyledForm>
);

export { Presentation };
